/* eslint-disable @typescript-eslint/no-require-imports */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Provider } from 'mobx-react';
import { useObservable, useObserver } from 'mobx-react-lite';
import React, { ReactElement, useEffect, Suspense } from 'react';
import { render } from 'react-dom';
import { RootApp } from '~/components';
import { RootRoute } from '../components';
import { BrowserRouter } from 'react-router-dom';
import intl from 'react-intl-universal';
import { JudgePf, NativeTools, postMessage, Tools, Url } from '~/utils';
import currentEnvConfig from '~/.config/api';
import LocalData from '~/utils/LocalData';
import RootStore from '~/stores';
import Store from './store';
import './style.scss';

const locales = {
  // eslint-disable-next-line @typescript-eslint/no-require-imports
  zh_CN: require('~/.config/i18n/zh-CN.ts').default,
  en_US: require('~/.config/i18n/en-US.ts').default,
  zh_TW: require('~/.config/i18n/zh_TW.ts').default,
  zh_HK: require('~/.config/i18n/zh-HK.ts').default,
  vi_VN: require('~/.config/i18n/vi_VN.ts').default,
};

//子账号禁用页面
const DisabledPath = ['/welfare', '/memberi', '/applyRecord', 'notice'];

//无需验证token的页面
const NoNeedTokenPath = [
  '/clause',
  '/agreement',
  '/agreements',
  '/memberServiceAgreement',
  '/monthlyVipAgreement',
  '/cancelAccountAgreement',
  '/displayJson',
  '/memberi',
  '/zfbVerified',
  '/xiechengAd',
  '/wxPayManage',
  '/applyRecord',
  '/customizedMembership',
  '/helpDetails'
];



// /zfbVerified页面由于是在支付宝app内打开，会按原生h5的逻辑处理
// 但我们都知道支付宝app没有提供需要的方法，这会导致页面初始化失败
// 将这种特殊页面设置为任何情况下直接打开. 谨慎添加
const OpenDirectlyPath = ['/zfbVerified'];

const isPageNoNeedToken = (pathName: string) => {
  const API_ENV = process.env.API_ENV;
  // *本地及测试环境直接展示
  const isDev = API_ENV !== 'prod';
  return NoNeedTokenPath.includes(pathName) || isDev;
};

function User(): ReactElement {
  let store = useObservable(new Store());
  let rootStore = new RootStore();
  let appStore = rootStore.app;
  let userStore = rootStore.user;
  // let loadStore = rootStore.loading;
  let dataStore = rootStore.data;
  //获取url中的语言参数
  const getUrlLang = () => {
    //支付宝验证页 的url是由;连接的，要特殊处理
    const url_支付宝验证 = '/zfbVerified';
    //其他页面
    if (location.pathname !== url_支付宝验证) return Url.getQueryString('lang');
    let searchArr = location.search?.split(';');
    let lang = '';
    for (let item of searchArr) {
      let arr = item.split('=');
      if (arr?.[0] === 'lang') {
        lang = arr?.[1];
        break;
      }
      console.log(item);
    }
    return lang;
  };
  const urlLang = getUrlLang();

  //国际化init
  const initLang = async(value?: string) => {
    
    //zh_CN zh_HK zh_TW vi_VN en_US
    let currentLocale = 'zh_CN';
    if (value && Object.keys(locales).includes(value)) {
      //客户端传来的lang
      currentLocale = value.toString();
    } else {
      //url带的lang
      if (urlLang) currentLocale = urlLang;
    }
    loadAgreements('', currentLocale);
    LocalData.setItem('lang', currentLocale);
    let pathName = window.location.pathname;
    intl
      .init({
        currentLocale,
        locales,
      })
      .then(async () => {
        if (isPageNoNeedToken(pathName)) store.finishInit();
        // else {
        // postMessage.send('getToken', null); //v1.0.5去掉这一句
        // postMessage.send('getToken', { cbName: 'setCookie' });
        NativeTools.getTokenFromNative(async (res) => {
          userStore.token = res;
          await store.finishInit();
        });
        // postMessage.send('getVersion', null);
        NativeTools.getVersionFromNative(async (data) => {
          appStore.version = data.version;
          //拉协议教程列表
          // if (pathName === '/about' || pathName === '/tutorial')
          loadAgreements(data.version, currentLocale);
        });
        // postMessage.send('nowIsReview', null);
        NativeTools.getIsReviewFromNative(async (data) => {
          appStore.isReview = data.isReview;
        });
        NativeTools.getMarketPlaceFromNative(async (marketPlaceName) => {
          appStore.marketPlace = marketPlaceName;
        });
        NativeTools.getAndroidReview(async (res) => {
          appStore.androidReview = res;
        });

        NativeTools.ngetIsReview(async (res) => {
          appStore.isReviewMode = res;
        });
        // NativeTools.getIsReview(async (res) => {
        //   appStore.isReview = res;
        // });
        NativeTools.getUidFromNative();
        // }
        // NativeTools.getEnterpriseSubAccount(
        //   async (data) => {
        //     // 是子账号
        //     if (data?.isEnterpriseSubAccount){
        //       if (DisabledPath.includes(pathName)) {
        //         // 重定向
        //         window.location.href = '/VIP404';
        //       }
        //     }
        //   }
        // );
      });
  };

  const loadAgreements = async (version: string, lang: string) => {
    // loadStore.setDisableLoading(true);
    await dataStore.getAgreements({ version: version, lang: lang });
    // loadStore.setDisableLoading(false);
  };

  useEffect(() => {
    //on mount
    const API_ENV = process.env.API_ENV;
    const isDev = API_ENV !== 'prod';
    if (!isDev) return;
    //测试环境才执行
    initLang();
  }, []);

  useEffect(() => {
    //直接在浏览器打开的处理
    (async () => {
      if (
        (!(await Tools.isInApp()) &&
          NoNeedTokenPath.includes(location?.pathname)) ||
        OpenDirectlyPath.includes(location?.pathname)
      )
        initLang();
    })();
    //init apiHost
    if (!LocalData.getItem('apiHost')?.trim())
      LocalData.setItem('apiHost', Tools.getConstUrls().host);
    //请求移动端原生接口
    window['responseLang'] = initLang;
    postMessage.send('requestLang', null);
    //(pc原生接口接收订阅返回)
    let unsubscribe;
    (async () => {
      unsubscribe = await postMessage.response('setLang', (event, json) =>
        initLang(json)
      );
    })();
    //环境切换(目前只对接了pc)
    postMessage.send('switchEnv', null);
    let unsubscribe2;
    (async () => {
      unsubscribe2 = await postMessage.response('switchEnv', (event, json) => {
        if (json?.toString()?.length) LocalData.setItem('apiHost', json);
      });
    })();
    return () => {
      unsubscribe?.();
      unsubscribe2?.();
    };
  }, []);
  //test
  return useObserver(() => {
    return (
      // *本地运行时不需要再注释这里了
      store.init && (
        // @ts-ignore
        <Provider {...rootStore}>
          <RootApp>
            <BrowserRouter>
              <Suspense fallback={<div />}>
                <RootRoute />
              </Suspense>
            </BrowserRouter>
          </RootApp>
        </Provider>
      )
    );
  });
}

render(<User />, document.getElementById('root'));
