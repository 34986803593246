import memberAgreementTxt from './memberAgreementTxt';
import monthlyVipAgreementTxt from './monthlyVipAgreementTxt';

// 关于我们
const about = {
  'about.tab.title': '关于我们',
  'about.welcome': `欢迎来到QuickFox`,
  'about.list.check': '检查更新',
  'about.list.contact': '联系我们',
  'about.list.like': '给个好评',
  'about.list.terms': '服务条款',
  'about.list.policy': '隐私协议',
  'about.list.vip': '会员服务协议',
};

// 联系我们
const contact = {
  'contact.tab.title': '联系我们',
  'contact.title': '您可以通过以下方式和我们取得联系',
  'contact.list.offcial': '官方网站',
  'contact.list.wx': '微信公众号',
  'contact.list.qq': '客服QQ',
  'contact.list.qqgroup': '官方QQ群',
  'contact.list.wxkf': '技术支持微信',
  'contact.list.email': '邮箱',
  'contact.list.op.visit': '去访问',
  'contact.list.op.copy': '复制号码',
  'contact.list.op.copyAccount': '复制帐号',
  'contact.list.op.copy.mail': '复制地址',
  'contact.copy': '复制成功',
};

// 帮助
const help = {
  'help.tab.title': '帮助',
  'help.title': '需要人工帮助？点击联系在线客服',
  'help.contact': '立即联系',
  'help.head.speedUp': '加速',
  'help.head.recharge': '充值',
  'help.head.other': '其他',
  'help.pv': '帮助页浏览量',
  'help.speed': '加速',
  'help.other': '其他',
  'help.vip': '会员',
};

// 帮助详情
const helpDetail = {
  'helpDetail.tab.title': '详情',
  'helpDetail.about': '相关问题',
};

const notice = {
  'notice.tab.title': '消息中心',
  'notice.nodata': '暂无任何消息哦~',
  'notice.numberOfMsgJumpClick': '站内信按钮跳转到会员页的点击量',
  'notice.getItRightnow': '点击领取',
  'notice.allReadyGetIt': '已领取',
  'notice.getItSuccessfully': '领取成功',
  'notice.rechargeNow': '立即充值',
};

// Http
const http = {
  'http.prompt.networkError': '网络错误',
};

const memberServiceAgreement = {
  'memberServiceAgreement.tab.title': '会员服务协议',
  'memberServiceAgreement.content': memberAgreementTxt,
};

const monthlyVipAgreement = {
  'monthlyVipAgreement.tab.title': '连续包月协议',
  'monthlyVipAgreement.content': monthlyVipAgreementTxt,
};

const Welfare = {
  'welfare.header.todayincome': '今日收益',
  'welfare.header.totalincome': '累计收益',

  'welfare.coinsTasks': '金币任务',

  'welfare.tasksCard.chekout': '查看',
  'welfare.tasksCard.coins': '金币',

  'welfare.tasks.invite': '邀请有礼',
  'welfare.tasks.invite.unit': '金币/人',
  'welfare.tasks.invite.desc': '邀请赚年卡会员',
  'welfare.tasks.invite.descript': '邀请好友拿金币, 百万红包等你兑',
  'welfare.tasks.sign': '每日签到',
  'welfare.tasks.sign.descript': '最高每日可获得50金币哦',
  'welfare.tasks.wx': '关注公众号',
  'welfare.tasks.bindEmail': '绑定有礼',
  'welfare.tasks.bindText': '去绑定',
  'welfare.tasks.bindEmail.descript': '绑定邮箱或手机号即可领取金币',
  'welfare.tasks.bindEmail.desc': '+6小时VIP时长',
  'welfare.tasks.bindEmail.bindTimes': '+6小时VIP',
  'welfare.tasks.share': '分享有奖',
  'welfare.tasks.share.descript': '在社交平台分享海报，截图上传领取100金币',
  'welfare.tasks.wx.descript': '关注QuickFox微信公众号即可领取30金币',
  //签到页面
  'welfare.sign.dayno': '第 {day} 天',
  'welfare.sign.vipTime': '{vipTime}小时VIP',
  'welfare.sign.day': '天',
  'welfare.sign.hadSign': '已连续签到',
  'welfare.sign.sign': '签到',
  'welfare.sign.signed': '已签到',
  'welfare.sign.tips': '连续签到可获得丰厚奖励哦~',
  'welfare.sign.successText': '签到成功',
  'welfare.sign.rule.title': '签到细则',
  'welfare.sign.rule.part1': '每连续签到3天，可获得2小时VIP时长；',
  'welfare.sign.rule.part2': '每连续签到7天，可获得4小时VIP时长；',
  'welfare.sign.rule.part3': '中断签到将重新开始计算天数；',
  'welfare.sign.rule.part4': '同一设备多次注册不记录签到奖励，禁止通过不正当手段获取签到奖励；',
  'welfare.sign.successTips':
    '签到成功, {todayReward} 金币到手, 记得每天都来哦~',
  'welfare.sign.signSuccess':
    '签到成功~',
  'welfare.sign.signSuccessVip':
    '签到成功, {vipTime}{timeType}VIP时长到手, 记得每天都来哦~',
  'welfare.sign.ruleDetail':
    '每日打卡签到可获得2金币, 每成功连续签到7天可获得额外金币奖励, 最高当日签到可获得50金币, 记得每天都来哦~',
  'welfare.sign.ruleDetail2':
    '同一设备多次注册不记录奖励金币，禁止通过不正当手段获取金币奖励，QuickFox有权收回金币奖励及关闭兑换订单',
  //关注公众号页面

  'welfare.wx.follow': '关注公众号',
  'welfare.wx.welfareForInvite': '邀请有礼',
  'welfare.wx.signIn': '分享有奖',
  'welfare.wx.toSignIn': '去签到',
  'welfare.wx.toFollow': '去关注',
  'welfare.wx.toInvite': '去邀请',
  'welfare.wx.toGet': '可得',
  'welfare.wx.toGetHighest': '最高获得',

  'welfare.wx.qrcodeTip.saved': '二维码已保存至相册',
  'welfare.wx.qrcodeTip.failed': '二维码保存失败了',
  'welfare.wx.qrcodeTip.version': '保存二维码需要下载最新版本!',
  'welfare.wx.Intro.title': '任务介绍',
  'welfare.wx.Intro.step': '步骤',
  'welfare.wx.Intro.follow': '关注',
  'welfare.wx.Intro.reward': '奖励',
  'welfare.wx.Intro.rewardText1.part1': '关注即获得',
  'welfare.wx.Intro.rewardText1.part2': '{vipCount}小时VIP时长',
  'welfare.wx.Intro.rewardText2': '参与公众号时长周周领，每周一次免费时长领取',
  'welfare.wx.Intro.pc.scanit': '使用手机微信“扫一扫”下方图片',
  'welfare.wx.Intro.mobile.scanit':
    '保存下方二维码至相册, 打开微信, 选择“扫一扫” 该图',
  'welfare.wx.Intro.follow.descrip': '点击右上角“关注”按钮, 即可完成任务',

  //邀请页面
  'welfare.invite.rule.title': '邀请规则',
  'welfare.invite.personNum': '已成功邀请 (人)',
  'welfare.invite.viewDetail': '查看明细',
  'welfare.invite.inviteDetail': '邀请明细',
  'welfare.invite.coinNum': '邀请奖励 (金币)',
  'welfare.invite.dayNum': '邀请奖励 (天)',
  'welfare.invite.duration': '获赠时长 (天)',
  'welfare.invite.toGoods': '金币兑换',
  'welfare.invite.rewardDetail': '奖励明细',
  'welfare.invite.face2face': '海报&面对面邀请点击量',
  'welfare.invite.copyLink': '复制邀请链接',
  'welfare.invite.post.saved': '海报已保存至相册',
  'welfare.invite.post.failed': '海报保存失败了',
  'welfare.invite.post.descrip.tips':
    '保存海报或截屏分享给好友, 邀请好友扫描二维码, 成功邀请后你和好友各得100金币',
  'welfare.invite.post.version': '保存海报需要下载最新版本!',
  'welfare.invite.post.pcAppTip': '请前往QuickFox APP端保存海报!',
  'welfare.invite.post.saveBtn': '保存海报',
  'welfare.invite.post.descrip.regist': ` 扫码注册 <br /> QuickFox账号`,
  'welfare.invite.post.descrip.speedOnce': `下载APP <br /> 使用一次加速`,
  'welfare.invite.post.descrip.getCoins': `您和好友 <br /> 各获得100金币`,
  'welfare.invite.process.regist': ` 好友点击 <br /> 邀请链接注册`,
  'welfare.invite.process.speedOnce': `下载APP <br /> 使用一次加速`,
  'welfare.invite.process.getCoins': `您和好友 <br /> 各获得100金币`,
  'welfare.invite.process.tips':
    '*好友需在新移动设备上（即之前未登录过QuickFox帐号的手机）登录在邀请链接上注册的帐号, 使用一次加速后方可获得奖励。',

  'welfare.invite.link.title': '你有超级VIP待领取',
  'welfare.invite.link.descrip':
    'QuickFox加速器发放福利啦, 注册即送超级VIP, 更有现金红包等你来抢！',
  'welfare.invite.historyBtn': '邀请明细',
};

const PageTitle = {
  'pageTitle.goods': '商城',
  'pageTitle.welfare': '福利',
  'pageTitle.invite.detail': '邀请明细',
  'pageTitle.taskDetails': '关注公众号',
  'pageTitle.inviteDetails': '邀请有礼',
  'pageTitle.taskSignin': '每日签到',
  'pageTitle.taskCenter': '任务中心',
  'pageTitle.taskEmail': '绑定邮箱',
  'pageTitle.taskShare': '分享有奖',
  'pageTitle.appApplyRecord': '申请记录',
};

const Common = {
  'common.ok': '好的',
  'common.confirm': '确定',
  'common.cancel': '取消',
  'common.avatar': '头像',
  'common.iSee': '我知道了',
  'common.human': '人',
  'common.requreUpdate': '当前版本过旧, 请更新至最新版本',
  'common.currency.dollar': '美元',
  'common.free': '免费',
  'common.currency.rmb': '元',
  'common.init': '正在初始化项目',
  'common.payType.offline': '线下支付',
  'common.payType.alipay': '支付宝',
  'common.payType.wxMiniProg': '微信小程序',
  'common.payType.wx': '微信', //微信支付
};

const Tips = {
  'tips.pleaselogin': '请先登录',
  'tips.pleaselogin.event': '登录后才可以参加活动哦~',
  'tips.gologin': '去登录',
  'tips.loadFailed': '加载失败',
  'tips.listLoadFailed': '加载列表失败',
  'tips.loadFailed.retry': '加载失败, 请稍后重试',
  'tips.refreshed': '已刷新',
  'tips.refreshFailed': '刷新出错了',
  'tips.saving': '保存中...',
  'tips.saveQrcode': '保存二维码',
  'tips.copyed': '复制成功',
  'tips.loadingHard': '拼命加载中, 请稍候...',
  'tips.loading': '加载中...',
  'tips.nodata.forNow': '暂无数据',
  'tips.load.success': '加载完成',
  'tips.goingToPay': '正在前往支付...',
  'tips.cancelSuccessful': '取消成功',
  'tips.failToRefreshOrder': '订单刷新失败',
  'tips.refreshOrderError': '订单刷新出错了',
  'tips.refreshListError': '刷新列表出错了',
  'tips.refreshListDone': '已刷新列表',
  'tips.pull2Refresh': '下拉可以刷新',
  'tips.release2Refresh': '释放立即刷新',
  'tips.refreshing': '正在刷新',
};
// 开通记录
const ActivationRecord = {
  'record.records': '开通记录',
  'record.options': '操作',
  'record.memberRefund': '会员退款',
  'record.syncOrder': '同步订单',
  'record.tip': '温馨提示',
  'record.tipDesc':
    '您确定要关闭QuickFox超级VIP连续包月服务吗？这将导致您的帐号停止续期，到期后将无法使用VIP专属线路。',
  'record.memberPage.pageViewsOfActivationRecord': '会员页-开通记录页浏览量',
  'record.refundFail.tryAgain': '退款申请失败, 请稍候再试',
  'record.cancel': '取消',
  'record.noRecordYet.goAndActive': '还没有记录哦, 快去开通会员吧~',
  'record.chooseRefundOrder': '选择退款订单',
  'record.weSupportRefund.oneUserHasOneService':
    '我们支持您在购买超级VIP会员后7天内申请退款, 请选择要退款订单, 退款后资金将原路返回。一个用户只可享受一次退款服务。',
  'record.failToGetList': '获取列表失败',
  'record.failToGetActivationList': '获取开通记录列表失败',
  'record.failToGetRefund': '获取退款情况失败',
  'record.getListFail': '获取列表失败',
  'record.recordItem.numberOfRefund':
    '会员页-开通记录页, 订单卡片上的退款按钮点击量',
  'record.recordItem.getTime': '获得时间',
  'record.recordItem.refundTime': '退款时间',
  'record.recordItem.refuseRefund': '不可退款',
  'record.recordItem.refundComplete': '退款已完成',
  'record.recordItem.addDay': '权益时长',
  'record.recordItem.handsel': '赠送',
  'record.recordItem.orderNumber': '订单编号',
  'record.recordItem.orderType': '支付方式',
  'record.recordItem.orderNumberHasBeenCopied': '订单编号已复制',
  'record.recordItem.refund': '退款',
  'record.recordItem.outActivity': '外部活动',
  'record.resaonModal.refundReason': '退款原因',
  'record.resaonModal.supplement': '补充说明',
  'record.resaonModal.refundReasonPlaceholder':
    '选填。告诉我们为什么您需要退款, 以便我们尽快进行优化, 最多可输入50个字。',
  'record.resaonModal.reasonLimitedText': '最多只能输入50个字哦',
  'record.resaonModal.failToSubmitRefundRequset': '退款申请提交失败了',
  'record.resaonModal.refundApplication': '退款申请',
  'record.reasonModal.failToRefund': '操作失败',
  'record.reasonModal.successfulRefund': '操作成功',
  'record.reasonModal.weSupportRefund.reviewDetailonITunes':
    '我们支持您在购买超级VIP会员后7天内申请退款, 请在iTunes上查看该订单并进行退款申请。',
  'record.reasonModal.numberOfConfirmRefund':
    '会员页-开通记录页, 确定退款按钮点击量',
  'record.reasonModal.ok': '好的',
};

// 时间类型(通用)
const TimeType = {
  'timeType.day': '天',
  'timeType.week': '周',
  'timeType.month': '月',
  'timeType.season': '季',
  'timeType.year': '年',
  'timeType.minute': '分钟',
  'timeType.hour': '小时',
  'timeType.sentence': ' {day} 天 {hour} 时 {minute} 分 {second} 秒',
};

// 防锁定教程
const tutorial = {
  'tutorial.mi': '小米手机教程',
  'tutorial.vivo': 'vivo手机教程',
  'tutorial.oppo': 'OPPO手机教程',
  'tutorial.huawei': '华为手机教程',
  'tutorial.honor': '荣耀手机教程',
  'tutorial.oneplus': '一加手机教程',
  'tutorial.samsung': '三星手机教程',
  'tutorial.general': '其他手机教程',
  'tutorial.tips': '请选择您的手机品牌, 查看相对应教程',
  'tutorial.title': '加速防掉线教程',
  'tutorial.purchase.title': '购买超级VIP',
  'tutorial.purchase.content0':
    '如您在使用Google Play支付购买会员时无法完成支付，可尝试使用以下途径购买：',
  'tutorial.purchase.title1': '1、前往官网购买会员',
  'tutorial.purchase.content1':
    '您可点击下方按钮前往官网购买会员，支持微信/支付宝/PayPal等支付方式，价格更优惠；',
  'tutorial.purchase.title2': '2、安装QuickFox最新版本',
  'tutorial.purchase.content2':
    '可前往QuickFox官网下载最新版本，支持微信/支付宝/PayPal等支付方式；',
  'tutorial.purchase.title3': '3、联系客服购买',
  'tutorial.purchase.content3':
    'QuickFox支持通过微信/支付宝/银行以扫码或转账的形式购买超级VIP，您可以点击APP左上角咨询APP客服或添加官方微信号QuickFox2020进行咨询；',
  'tutorial.purchase.button': '去官网页面购买',
  'tutorial.purchase.button1': '下载最新版本',
};
// 邀请裂变1, 2期活动结束
const pointEventEnd = {
  'pointEventEnd.tooltipText': '很遗憾, 活动已结束',
  'pointEventEnd.invitationWithPrize': '邀请有奖',
  'pointEventEnd.scanQRToFollow': '扫码关注公众号 下次再约哦~',
  'pointEventEnd.scanQRToFollowForMoreInfo':
    '扫码关注QuickFox公众号, 更多精彩活动资讯一手掌握',
  'pointEventEnd.copyOfficialAccount': '复制公众号',
};
// 邀请裂变第3期
const taskCenterV3 = {
  'task.mission.pv': '任务中心页浏览量',
  'task.lookforwardForMoreTasks': '更多任务敬请期待',
  'task.taskCard.isPcAppNotice':
    '邀请任务暂时仅支持在移动端完成, 请扫码下载QuickFox APP进行任务。',
  'task.taskCard.numberOfInvitationClick': '邀请任务点击量',
  'task.taskCard.numberOfFollowTaskClick': '关注公众号任务点击量',
  'task.taskCard.numberOfCheckInClick': '福利页, 签到任务点击数',
  'task.detail.follow.numberOfFollowPage': '关注公众号任务页浏览量',
  'task.invite.invitePagePv': '邀请任务页浏览量',
  'task.invite.cpoyPv': '复制邀请链接点击量',
  'task.invite.loadingText': '还没有记录哦,快去邀请朋友吧~',
  'task.invite.nodataText': '还没有记录哦, 快去做任务获得金币吧~',
  'task.invite.noContentText': '暂无记录',
  'task.invite.noContentTextPart1': '快去邀请朋友吧',

  'task.invite.coinDetail': '金币明细',
  'task.invite.coinDetailPv': '金币明细浏览量',

  'task.invite.numberOfClickInviteBtn': '邀请按钮点击量',
  'task.invite.posterAndFace2Face': '海报&面对面邀请',
  'task.invite.inviteForNow': '立即邀请',
  'task.invite.linkCopied': '链接已复制',
  'task.invite.posterLoading': '海报正在加载...',
  'task.invite.descript.title.part1': '每邀请一位好友, 最多可以获得',
  'task.invite.descript.title.part2': '快去邀请吧',

  'task.invite.reward.descrip': '奖励说明',
  'task.invite.reward.descrip.part1':
    '1. 被邀请好友在移动端使用一次加速后，您与好友各获得1天VIP时长奖励。',

  'task.invite.reward.descrip.part2':
    '2. 被邀请好友完成VIP购买后，您即可获得好友购买时长的10%作为邀请奖励。',
  'task.invite.reward.descrip.part3':
    '3. 使用不正当手段批量注册，将被判定为违规注册，无法获得邀请奖励。',
  'task.invite.reward.descrip.part4':
    '4. 若好友发生退款，您所获得的对应邀请奖励将被同步取消。',
  'task.invite.reward.descrip.part5':
    '2. 被邀请好友完成VIP购买后，您即可获得好友购买时长的10% + 5%作为邀请奖励。',
    

  'task.invite.details.tab.waitingData': '待加速',
  'task.invite.details.tab.waitingToGiveData': '待发放',
  'task.invite.details.tab.completedData': '已完成',
  'task.invite.details.tab.inValidData': '已失效',
  'task.invite.details.tab.closed': '已关闭',
  'task.invite.details.tab.toAppeal': '待申诉',
  'task.invite.details.tab.toPaid': '待支付',
  'task.invite.details.tab.violationData': '违规用户',
  'task.invite.details.content.type1':
    '好友已完成注册，让好友在新设备上下载APP并使用该账号进行一次加速，奖励将在1小时内到账。',
  'task.invite.details.content.type2': '已成功邀请好友, 1天VIP时长奖励到帐咯',
  'task.invite.details.content.type3':
    '哎呀，该好友没有在新设备进行首次加速，本次邀请失败了~',
  'task.invite.details.content.type4':
    '已成功邀请好友, 系统风险审核中，您和好友的1天VIP时长奖励将在1小时内到帐',
  'task.invite.details.content.type5': '该好友存在违规操作, 无法发放奖励',
  'task.invite.details.loading': '邀请明细正在加载...',
  'task.invite.details.load.faild': '邀请明细加载失败',

  'task.signin.viewPv': '福利-签到页, 页面浏览量',
  'task.signin.walfare': '新用户签到福利',
  'task.signin.walfare.detail':
    '接受好友邀请注册后前7天, 连续签到3天, 将可以获得额外100金币奖励。您也可以参与邀请活动, 邀请朋友一起签到, 可获得同等签到金币奖励哟。',
  'task.signin.walfare.downloadForSignin': '扫码下载QuickFox APP邀请好友签到',
  'task.signin.walfare.goToInviteWithPhone': '去手机端邀请',
  'task.signin.walfare.goToInvite': '去邀请>',
  'task.signin.walfare.numberOfCheckInClick': '福利-签到任务页, 签到点击数',
  'task.signin.walfare.signing': '签到数据加载中, 请稍后...',
  'task.signin.walfare.signSuccessTipsPart1':
    '签到成功, {todayReward}金币到手, ',
  'task.signin.walfare.signSuccessTipsPart2':
    '因您获得新用户连续签到福利, 再送您100金币~',
  'task.signin.walfare.signSuccessTipsPart3': '记得每天都来哦~',

  'task.goods.detail.title': '商品详情',
  'task.goods.detail.lessCoin': '金币不足',
  'task.goods.detail.lessCoinTips': '你的金币不足哦, 快去做任务赚取金币吧~',
  'task.goods.detail.lessCoinTips.goForMoreCoin':
    '你的金币不足哦, 快去做任务赚取金币吧~<br>ios设备暂不支持补差价兑换, 请在其他系统设备上进行兑换操作',

  'task.goods.detail.exchangeRes.goCheck':
    '已兑换成功，可前往“兑换记录”页面查看领取商品',

  'task.goods.detail.exchange': '兑换商品',
  'task.goods.detail.exchangeSuccessful': '兑换成功',
  'task.goods.detail.exchangeSuccessful.congratulate':
    '恭喜, 你已兑换成功, 可通过下列途径领取商品~',
  'task.goods.detail.exchangeSuccessful.tips':
    '恭喜, 你已申请兑换成功, 我们将在2个工作日内完成审核, 可在“兑换记录”页面查看进度哦~',
  'task.goods.detail.exchangeSuccessful.getVip':
    '兑换成功, 超级VIP特权已充值到您账户',
  'task.goods.detail.exchange.fail.retry': '兑换失败, 请稍后再试',
  'task.goods.detail.exchangeConfirm': '确定兑换该商品吗？',
  'task.goods.detail.stock.less': '库存不足',
  'task.goods.detail.stock.less.tips': '该商品库存不足, 暂时无法兑换哦~',
  'task.goods.detail.signinDay.less': '签到天数不足',
  'task.goods.detail.signinDay.less.tips':
    '该商品需要签到{day}次及以上才可以兑换哦, 快去签到吧~',
  'task.goods.detail.remain': '剩余',
  'task.goods.detail.tillNext': '距下次抢购还有',
  'task.goods.blockTitle': '商品介绍',
  'task.goods.noStock': '暂无库存',
  'task.goods.noConvertible': '不可兑换',
  'task.goods.overConvertibleLimit': '已达兑换上限',
  'task.goods.exchange.now': '立即兑换',
  'task.goods.order.total': '订单总额',
  'task.goods.coinForPay': '支付金币',
  'task.goods.require.diff': '需补差价',
  'task.goods.pay.diff': '补差价',
  'task.goods.pay.diff.exchange': '支付差价兑换',
  'task.goods.pay.way': '选择支付方式',
  'task.goods.contact.offcialAccount': '联系QuickFox微信客服{number}',
  'task.goods.offcialAccount.copy': '(点击复制微信号)',
  'task.goods.contact.customerService': '联系QuickFox微信客服',
  'task.goods.go': '立即前往',

  'task.point.detail.coinType0': '金币抽奖',
  'task.point.detail.coinType1': '金币兑换',
  'task.point.detail.coinType2': '邀请好友 {account} 注册',
  'task.point.detail.coinType3': '通过好友邀请注册',
  'task.point.detail.coinType4': '用户排名靠前平台奖励',
  'task.point.detail.coinType5': '每日签到',
  'task.point.detail.coinType6': '关注微信公众号',
  'task.point.detail.coinType7': '商城兑换订单失效',
  'task.point.detail.coinType8': '金币抽奖收益',
  'task.point.detail.coinType9': '连续签到3天额外奖励',
  'task.point.detail.coinType10': '好友连续签到3天额外奖励',
  'task.point.detail.bindReward': '绑定赠送',
  'task.point.detail.shareReward': '分享任务奖励',
  'task.point.detail.coinType11': '中秋活动金币赠送',
  'task.point.detail.coinType13': '金币到期',
  'task.point.detail.coinType16': '世界杯竞猜活动',

  'task.redeem.modal.title': '暂不支持',
  'task.redeem.modal.content':
    'ios设备暂不支持补差价兑换, 请在其他系统设备上进行兑换操作',
  'task.redeem.exchangeRecordPv': '兑换记录浏览量',
  'task.redeem.exchangeRecord': '兑换记录',
  'task.redeem.exchangeCode': '兑换码：',
  'task.redeem.draw.link': '领取链接：',
  'task.redeem.draw.code': '领取商品码：',
  'task.redeem.exchangeCode.use': '兑换方式',
  'task.redeem.norecord.goForIt': '还没有记录哦, 快去兑换商品吧~',
  'task.redeem.record.tips':
    'QuickFox将在2个工作日（周一至周五为工作日）内完成发放, 发放后请尽快查看使用, ',
  'task.redeem.clickForIt': '点此查看',
  'task.redeem.copy': '复制',
  'task.redeem.copied': '已复制',
  'task.redeem.payForNow': '立即支付',
  'task.redeem.orderWill': '订单将于 {time} 失效, 请及时支付',
  'task.redeem.dataReady': '数据准备中, 请稍候...',
  'task.redeem.drawed': '抽中',
  'task.redeem.receive': '立即领取',
  'task.redeem.drawed.limitDate': '奖品有效期至',
  'task.redeem.drawed.waitForit':
    '抽中 {lotteryName} , 等待发放（2个工作日内完成发放）',
  'task.redeem.drawed.waitForit.physical':
    '抽中 {lotteryName} , 请联系微信客服chenhan_xy领取',
  'task.redeem.drawed.violation':
    '抽中 {lotteryName} , 因违规获得金币, 该订单已被关闭',
  'task.redeem.drawed.success':
    '抽中 {lotteryName} , 会员已充值到您账户, 请注意查收',
  'task.redeem.drawed.goods.success':
    '抽中 {lotteryName} , 已充值到您账户, 请注意查收',
  'task.redeem.drawed.goods.success.physical':
    '抽中 {lotteryName} , 奖品已完成发放。',
  'task.redeem.drawed.svip.success': '超级VIP会员已充值到您账户, 请注意查收',
  'task.redeem.drawed.vip.success': 'VIP会员已充值到您账户, 请注意查收',
  'task.redeem.drawed.violation.closed': '因违规获得金币, 该订单已被关闭',
  'task.redeem.violation.contact':
    '因违规获得金币, 不予发送抽奖奖励。如有疑问请发申诉材料到邮箱：info@quickfox.com.cn。',
  'task.redeem.limit.contact':
    '因被邀请账号存在异常操作, 暂且限制兑换。请发申诉材料到邮箱：info@quickfox.com.cn。申诉材料包括QuickFox账号, 近期邀请所有好友的聊天记录进行申诉。',
  'task.redeem.order.fail': '该笔订单已失效, 所消耗金币已退回账户',

  'task.redeem.draw.customer.service':
    '请及时查看兑换商品的详情描述，添加客服微信完成兑换。',
  'task.redeem.drawLink.tips': '领取链接：等待发放（2个工作日内完成发放）',
  'task.redeem.drawLink.limit.tips': '商品码将在{expireTime}过期, 请尽快兑换',
  'task.redeem.noname': '暂无名称',
  'task.redeem.checkMethod': '查看兑换方式',

  'task.event.rule': '活动规则',
  'task.event.question': '常见问题',
  'task.event.needToKnow': '活动须知',

  'task.goods.loadGoods.fail': '商品列表加载失败',
  'task.goods.coin.loadFailed': '金币数加载失败',
  'task.goods.shop.requreUpdate':
    '金币商城访问异常, 为保证更好的用户体验, 请您升级到最新客户端',
  'task.goods.coin.less.loadFailed': '剩余金币数加载出错了',
  'task.goods.coin.balance': '金币余额',
  'task.goods.coin.detail.view': '查看金币收支记录',
  'task.goods.coin.exchange.record': '查看商城兑换订单',

  'task.lottery.detailPv': '抽奖商品详情页浏览量',
  'task.lottery.waitMsg': '正在抽奖, 再等等, 马上开奖了~',
  'task.lottery.drawOut': '奖品被抽光啦, 正在补充奖品中, 稍后再来哦~',
  'task.lottery.networkErrMsg': '当前网络异常, 请检查网络后重试~',
  'task.lottery.Replenishment': '奖品被抽光啦, 正在补充奖品中, 稍后再来哦~',
  'task.lottery.balance.lessMsg': '金币余额不足, 快去赚金币吧~',
  'task.lottery.forAWhile': '当前无法进行抽奖, 晚点再来看看吧',
  'task.lottery.luckyId.error': '中奖id错误：',
  'task.lottery.luckyId.cannotFind': '没找到奖品, 中奖id为：',
  'task.lottery.interrupt': '哎呀, 抽奖意外中断了！请稍后重试或联系客服',
  'task.lottery.start.first': '请先开始',
  'task.lottery.number.error': '数字错误',
  'task.lottery.now': '立即',
  'task.lottery': '抽奖',
  'task.lottery.add': '补充',
  'task.lottery.rewards': '奖品中',
  'task.lottery.draw.congratulate': '恭喜！您抽中了',
  'task.lottery.coin.once': '金币/次',
  'task.lottery.draw.view':
    ', 等待系统发放后即可领取, 可在“福利-兑换记录“页查看进度',
  'task.lottery.draw.viewReward':
    ' , 奖品已发送到您账户, 可在“福利-兑换记录”页查看',
  'task.lottery.draw.physicalGoods':
    ' , 请及时联系客服领取, 可在“QuickFox App-福利-兑换记录”页查看联系方式及进度~',
  'task.lottery.rule': '抽奖规则',
  'task.lottery.rulePv': '抽奖规则点击量',
  'task.lottery.myRewardPv': '我的奖品点击量',
  'task.lottery.tab.item1.part1': '活动',
  'task.lottery.tab.item1.part2': '规则',
  'task.lottery.tab.item2.part1': '我的',
  'task.lottery.tab.item2.part2': '奖品',
  'task.lottery.coin.earnIt': '赚金币>>',
  'task.lottery.more': '更多抽奖',
  'task.lottery.result': '抽奖结果',
  'task.lottery.again': '再抽一次',
  'task.lottery.toView': '前往查看',
  'task.lottery.lickDrawNowPv': '“立即抽奖”按钮成功点击量',
  'task.lottery.error': '抽奖报错了：',
  'task.lottery.notLogin': '未登录',

  'task.walfare.center': '福利中心',
  'task.walfare.info.refresh.done': '已刷新个人信息',
  'task.walfare.info.refresh.error': '刷新个人信息出错了',
  'task.walfare.refreshed': '已刷新',
  'task.walfare.refresh.error': '刷新出错了',
  'task.walfare.viewPv': '福利页浏览量',
  'task.walfare.all': '全部',
  'task.walfare.giftMoney': '红包',
  'task.walfare.coin.clickPv': '金币明细点击量',
  'task.walfare.coin.left': '剩余金币',
  'task.walfare.coin.mine': '我的金币',
  'task.walfare.coin.expire.thisMonth': '本月到期金币',
  'task.walfare.coin.expire.time': '到期时间',
  'task.walfare.coin.earn': '赚取金币',
  'task.walfare.exchange.record': '兑换记录',
  'task.walfare.exchange.recordPv': '兑换记录点击量',
  'task.walfare.coin.descrip': '金币说明',
  'task.walfare.coin.descrip.usage': '金币有什么用？',
  'task.walfare.coin.descrip.usage.part1':
    '金币是您在QuickFox的虚拟货币资产, 您可使用金币在福利中心兑换现金红包、超级VIP会员等各种商品。',
  'task.walfare.coin.descrip.channel': '如何获得金币？',
  'task.walfare.coin.descrip.channel.part1':
    '可通过完成每日签到、邀请好友、关注QuickFox公众号等任务获得金币。',
  'task.walfare.coin.descrip.expire': '金币到期说明：',
  'task.walfare.coin.descrip.expire.part1':
    '每月的产生的金币将在3个月后到期，本月将到期的金币将进入到期金币列表中，兑换时优先消耗即将到期金币。若未及时兑换，金币将过期失效，无法找回。',
  'task.walfare.coin.noticePv': '公告点击量',
  'task.walfare.detail.view': '查看详情',
  'task.walfare.tasks.more': '更多任务',
  'task.walfare.tasks.clickPv': '更多任务点击量',

  'task.goods.clickPv': '商品点击量',

  // 邀请活动2期
  'welfare.invitev2.page.title': '赠送会员给好友',
  'welfare.invitev2.page.getFail': '获取数据失败',
  'welfare.invitev2.page.directions':
    '友情提示：激活赠送名额后，您最新的一笔付费订单将失去7天无理由退款资格。',
  'welfare.invitev2.rule.title': '赠送规则',
  'welfare.invitev2.rule.content': `
    1、拥有VIP特权的用户可每月激活并领取3个会员赠送名额（需成功购买过会员，且激活时赠送名额时自身会员剩余时长≥30天）。在规定时间内通过链接形式分享给好友，好友完成注册后即可领取对应时长的QuickFox会员卡；<br/>
    2、每次激活赠送名额时，您最新一笔付费订单将撤销7天无理由退款资格，请在确认已同意该规则后再激活名额；<br/>
    3、用户参加活动过程中，如用户存在以所获得的优惠权益进行盈利或非法获利、以任何形式转让或转移其所享有的优惠权益、非本人使用的，或者QuickFox有合理理由怀疑用户存在不当使用权益的，QuickFox将取消用户的权益资格，并有权撤销相关违规交易、收回优惠权益；<br/>
    4、如有任何关于本活动的资讯和帮助，请咨询QuickFox在线客服，本活动与苹果公司（Apple Inc.）无关；<br/>
    5、在法律范围内，QuickFox对本次活动保留最终解释权；<br/>`,
  'welfare.invitev2.link.title': '赠送你一张QuickFox会员卡',
  'welfare.invitev2.link.descrip':
    'QuickFox回国加速器，华人回国加速首选品牌，加速快人一步',

  'welfare.invitev2.card.type': 'QuickFox会员',
  'welfare.invitev2.card.nocheck': '无人领取',
  'welfare.invitev2.card.sendTo': '已成功赠送给尾号{account}好友',

  'welfare.invitev2.msgs.title': '给好友留言',
  'welfare.invitev2.msgs.next': '换一条',

  'welfare.invitev2.btn.allCheck': '会员卡已全部被好友领取',
  'welfare.invitev2.btn.giftNow': '立即赠送',
  'welfare.invitev2.btn.timeEnd': '赠送时间已结束',
  'welfare.invitev2.btn.submitAgain': '申请继续赠送',
  'welfare.invitev2.btn.upgradeToVip': '升级为VIP',
  'welfare.invitev2.btn.leftTime': '剩余赠送时间',
  'welfare.invitev2.btn.copyLink': '复制分享链接',
  'welfare.invitev2.btn.copied': '赠送链接已复制',
  'welfare.invitev2.modal.cantGift': '当前无法赠送会员',
  'welfare.invitev2.modal.content':
    '成功购买一次会员后可赠送会员给好友，好友注册后就可获得QuickFox会员哦~',
  'welfare.invitev2.modal.getIt': '知道了',
  'welfare.invitev2.modal.buyVip': '立即购买会员',
  'task.detail.email.title': '完善账号信息任务',
  'task.detail.email.rule1': '活动规则',
  'task.detail.email.rule2': '用户可通过绑定邮箱或手机号领取奖励',
  'task.detail.email.rule3': '本次活动奖励只可领取一次，无法重复领取',
  'task.detail.email.rule4':
    '已绑定邮箱、手机号的用户可通过本页面直接领取活动奖励。通过邮箱、手机号注册的用户将自动下发奖励',
  'task.detail.email.rule5':
    '赠送时长实时到账。优惠券码通过邮件、短信方式发送，仅可通过信息内的短网址参与',
  'task.detail.email.btn': '绑定并领取',
  'task.detail.email.received': '已领取',
  'task.detail.email.finished': '活动已结束',
  'task.detail.email.bind': '立即绑定',
  'task.detail.email.bound': '立即领取',
  'task.detail.email.success': '成功领取',
  'task.detail.email.failed': '领取失败',
  'task.detail.email.verify.pleaseEnterCorrectEmail': '请输入正确的邮箱地址',
  'task.detail.email.verify.pleaseEnterEmail': '请输入邮箱地址',
  'task.detail.email.verify.pleaseEnterCorrectPhone': '请输入正确的手机号码',
  'task.detail.email.verify.pleaseEnterPhone': '请输入手机号码',
  'task.detail.email.verify.pleaseEnterCorrectVerifyCode': '请输入正确的验证码',
  'task.detail.email.verify.pleaseEnterVerifyCode': '请输入验证码',
  'task.detail.email.verify.pleaseEnterDigitPsw':
    '请输入6-16位数字字母组合密码',
  'task.detail.share.title': '分享有奖',
  'task.detail.share.min5m': '请选择小于5M大小的图片',
  'task.detail.share.noPass': '审核不通过，重新提交',
  'task.detail.share.submit': '提交审核',
  'task.detail.share.underReview': '审核中',
  'task.detail.share.pass': '审核通过，已发放奖励',
  'task.detail.share.save': '保存图片',
  'task.detail.share.cancel': '取消',
  'task.detail.share.noUpload': '请先上传分享截图！',
  'task.detail.share.saveSuccess': '图片已保存至相册',
  'task.detail.share.saveFail': '保存图片失败！',
  'task.detail.share.successTip': '已提交审核，请耐心等待',
  'task.detail.share.version': '当前版本较低，请前往“我的-我的账号”页完成绑定',
  'task.detail.share.message': '请上传图片!',
};

// 邀请裂变第4期
const pointEventV4 = {
  'V4.welfare.center.title': '福利中心',
  'V4.welfare.center.more': '更多福利',
  'V4.welfare.foreignStudent.title': '留学生专属福利',
  'V4.welfare.foreignRule.title': '规则说明',
  'V4.welfare.redeem.norecord.goForIt': '还没有记录哦，快去领取福利吧~',

  'V4.welfare.record.title': '福利记录',
}

const cancel = {
  'cancel.title.reminder': '注销提醒',
  'cancel.title.auth': '身份验证',
  'cancel.reminder.artical': `
   <div  style="color: #90A1DC; ">
        注销是不可逆操作，请认真阅读以下重要提醒：
      </div>
      <p>
        1、注销前请确认已解约所有支付平台的连续包月服务， 可查看对应<a href="{url}">解约教程</a>完成解约。为了保障您的权益，未解约无法完成注销；
      </p>
      <p>
        2、帐号申请注销到注销成功有15天的等待期，在等待期内可随时终止注销。等待期结束后帐号将完成注销；
      </p>
      <p>
        3、帐号一旦被注销，所有帐号数据将清空。被注销帐号无法登录、无法找回。其所有权益，包含但不限于剩余会员天数、金币余额等，也将清空无法找回；
      </p>
      <p>
        4、注销后，该帐号关联的手机号、邮箱地址及第三方登陆信息将释放，登陆信息在释放后可以用于其他帐号注册或绑定；
      </p>
       <p>
        5、已注销账号重新注册时，无法获得该账号之前已享受过的新用户福利，包括但不限于新用户超级VIP时长赠送，邀请活动金币奖励等；
      </p>
  `,
  'cancel.reminder.agree': '已阅读并同意：QuickFox ',
  'cancel.reminder.agreement': '帐号注销协议',
  'cancel.reminder.next': '下一步',
  'cancel.reminder.read': '请认真阅读上述提醒',
  'cancel.reminder.cancelSign':
    '当前尚未解约{signTypeText}连续包月服务，请确认完成解约后再进行注销操作',
  'cancel.auth.input': '请输入当前账号密码完成验证',
  'cancel.auth.confirm': '确认注销',
  'cancel.auth.tips':
    '提交注销后，您将有15天注销等待期，等待期内可随时重新登录以终止注销。',
  'cancel.auth.pwd': '请输入密码',
  'cancel.auth.relogin.third': '请重新登录任一第三方账号以完成验证',
  'cancel.auth.relogin': '重新登录',
  'cancel.auth.please': '请',
  'cancel.auth.finish': '已完成验证',
  'cancel.auth.unDetected': '未检测到第三方账号，请尝试联系客服处理',
  'cancel.auth.inconsistent':
    '本次登录第三方帐号与当前绑定第三方账号不一致，请重试',
  'cancel.auth.failed': '第三方账号校验失败',
  'cancel.auth.failed.accident': '验证失败',
  'cancel.wx.pay': '管理自动续费',
  'cancel.tips.userInfoFailed': '未获取到用户信息，请稍后重试',
  'cancel.tips.unexpectedError': '发生意外的错误',
  'cancel.tips.failToCheckSignStatus': '查询签约状态失败',
};
const memberi = {
  'memberi.title': '兑换会员',
  'memberi.valicode': '请输入兑换码',
  'memberi.loginTip': '登录后才可以兑换哦',
  'memberi.loginBtn': '登录/注册',
  'memberi.placeholder': '请输入兑换码,注意区分大小写',
  'memberi.getcode': '立即兑换',
  'memberi.descTitle': '兑换规则',
  'memberi.desc1': '兑换码可通过参与QuickFox或合作商家相关活动获得',
  'memberi.desc2': '兑换成功即可获得对应的超级VIP时长',
  'memberi.desc3':
    '已拥有超级VIP会员时长的用户，及仍在签约状态中的用户，将在兑换成功后顺延超级VIP时长',
  'memberi.desc4':
    'QuickFox在法律规定的范围内享有本规则的解释权。如有疑问，请咨询联系在线客服',
  'memberi.success': '兑换成功',
  'memberi.success1': '已获得',
  'memberi.ok': '好的',
  'memberi.blacklistTips': '当前帐号存在风险，无法使用兑换码',
};

const customizedMembership = {
  'customizedMembership.viptimer': '时长剩余',
  'customizedMembership.concat': '立即咨询客服，获得独享定制线路',
  'customizedMembership.desc1': '独享带宽保障',
  'customizedMembership.desc2': '4K影音秒开',
  'customizedMembership.desc3': '流畅开播',
  'customizedMembership.desc4': '主备双线双倍稳定',
  'customizedMembership.desc5': '三设备同时在线',
  'customizedMembership.desc6': '全客户端支持',
  'customizedMembership.btn': '咨询续费',
  'customizedMembership.btn1': '咨询购买',
  'customizedMembership.title': '定制会员',
};

const sportsQuiz = {
  'sportsQuiz.title': '赛事详情',
  'sportsQuiz.shareAndGetCoins': '快速分享赚金币',
  'sportsQuiz.how2play': '玩法介绍',
  'sportsQuiz.rule': '玩转世界杯活动规则',
  'sportsQuiz.guessed': '您已竞猜',
  'sportsQuiz.advance': '晋级',
  'sportsQuiz.win': '获胜',
  'sportsQuiz.draw': '平局',
  'sportsQuiz.guessWithCoins': '使用{coins}金币竞猜',
  'sportsQuiz.ifGuessRight': '竞猜正确即可获得',
  'sportsQuiz.includes': '比赛结果包含加时赛，点球大战',
  'sportsQuiz.leftTime': '剩余竞猜时间',
  'sportsQuiz.leftQuota': '剩余参与名额',
  'sportsQuiz.end': '本场赛事竞猜已结束',
  'sportsQuiz.others': '请关注其他场次比赛~',
  'sportsQuiz.getFromNotice': '（通过消息中心领取）',
  'sportsQuiz.youHaveGuessed': '您已参与竞猜',
  'sportsQuiz.ifThen': '若 {predict}{result}您可获得 ',
  'sportsQuiz.guessedRight': '恭喜竞猜正确，您的 ',
  'sportsQuiz.goGet': ' 已发放，快去消息中心领取吧～',
  'sportsQuiz.yourGuess': '您的竞猜为：',
  'sportsQuiz.guessedWrong': '竞猜错误，下次继续加油哦~',
  'sportsQuiz.goGetCoins': '去赚金币',
  'sportsQuiz.platforms': '您可以前往以下直播平台观战',
  'sportsQuiz.useQF': '使用Quickfox影音模式，一键解锁版权限制',
  'sportsQuiz.cctv': '央视影音',
  'sportsQuiz.yangshipin': '央视频',
  'sportsQuiz.migu': '咪咕视频',
  'sportsQuiz.tiktok': '抖音',
};

const device = {
  'device.title': '已登录设备',
  'device.dialog.title': '退出登录',
  'device.dialog.centent':
    '退出登录后该设备再次登录需要验证账号密码，您确定要退出吗',
  'device.dialog.ok': '确定',
  'device.dialog.cancel': '取消',
  'applyRecord.title': '申请记录',
  'applyRecord.exitFailed': '已拒绝', //已拒绝
  'applyRecord.using': '已上架', // 已上架
  'applyRecord.waiting': '待处理', // 已上架
  'device.thisDevice': '本机',
  'device.exit': '退出',
  'device.offline': '下线',
  'device.exitSuccess': '退出成功',
};

// 绑定邮箱活动
const bindEmail = {
  'bindEmail.title': '绑定邮箱',
  'bindEmail.success.tip': '绑定邮箱/手机号成功！',
  'bindEmail.areaCode': '区号',
  'bindEmail.phoneEmpty': '请输入手机',
  'bindEmail.emailEmpty': '请输入邮箱地址',
  'bindEmail.switchTo': '切换至',
  'bindEmail.getCode': '获取验证码',
  'bindEmail.findCountry': '请输入国家或者区号查找',
  'bindEmail.emailVerify': '请输入正确的邮箱地址',
  'bindEmail.phoneVerify': '请输入正确的手机号码',
  'bindEmail.codeVerify': '请输入正确的验证码',
  'bindEmail.pwsVerify': '6-16位数字字母组合密码',
  'bindEmail.pwsEmpty': '请输入密码',
  'bindEmail.codeEmpty': '请输入验证码',
  'bindEmail.phone': '手机',
  'bindEmail.email': '邮箱',
};

// 任务补充
const taskSupplement = {
  'taskSupplement.redemptionDetails': '兑换详情页',
  'taskSupplement.missionRules': '任务规则',
  'taskSupplement.shareScreenshot': '选择分享截图',
  'taskSupplement.supportChannel': '当前支持小红书微信微博抖音等平台的分享截图',
  'taskSupplement.advisoryService': '请遵守任务规则,如有疑问请咨询客服',
  'taskSupplement.auditPrompt1': '审核期间请保持该分享内容可见',
  'taskSupplement.auditPrompt2': '审核通过后将自动发放100金币奖励,请耐心等待哦',
  'taskSupplement.sharePoster': '分享海报',
  'taskSupplement.saveImg': '长按图片保存',
  'taskSupplement.rulesDesc4':
    '即日起，所有注册用户都可在任务页面上传在社交平台（包括小红书、微信、微博、抖音、Facebook、Twitter、Instagram、Tiktok等平台）分享带有QuickFox分享海报的截图，审核通过后即可获得100金币奖励',
  'taskSupplement.rulesDesc5':
    '每个用户都可完成一次任务，任务审核期间，请保持该分享内容在社交平台可见，否则可能导致审核不通过',
  'taskSupplement.rulesDesc6': '平台将在2个工作日内完成审核，请耐心等待',
  'taskSupplement.rulesDesc7':
    '在用户参加营销活动过程中，如用户存在以所获得的优惠权益进行盈利或非法获利、以任何形式转让或转移其所享有的优惠权益、非本人使用的，或者QuickFox有合理理由怀疑用户存在不当使用优惠工具或优惠权益的，QuickFox将取消用户的权益资格，并有权撤销相关违规交易、收回优惠权益（含已使用及未使用的）',
  'taskSupplement.rulesDesc8':
    '如有任何关于本活动的资讯和帮助，请咨询QuickFox在线客服，本活动与苹果公司（AppleInc.）无关',
  'taskSupplement.rulesDesc9': '在法律范围内，QuickFox对本次活动保留最终解释权',
  'taskSupplement.xiecheng': '携程落地页',
};

// 微信续费补充
const wxPayManage = {
  'wxPayManage.activatedBusiness': '已开通业务',
  'wxPayManage.weChatPay': '微信支付',
  'wxPayManage.continuousMonthlyMembership': '连续包月会员',
  'wxPayManage.nextRenewalTime': '下次自动扣费时间',
  'wxPayManage.stopService': '关闭服务',
  'wxPayManage.successfullyUnsubscribe': '微信连续包月退订成功',
};

// 支付宝实名认证
const zfbManage = {
  'zfbManage.cancelledVerification': '用户取消验证',
  'zfbManage.enterNumber': '请输入正确的18位身份证号码',
  'zfbManage.enterX': '若身份证末尾含有字母请输入',
  'zfbManage.enterID': '请输入身份证登记的身份证号',
  'zfbManage.enterName': '请输入身份证登记的真实姓名',
  'zfbManage.verificationFailed': '身份证信息验证失败',
  'zfbManage.getCode': '请返回客户端重新获取二维码',
  'zfbManage.realNameverify': '实名认证',
  'zfbManage.successed': '恭喜您完成实名认证',
  'zfbManage.complete': '完成',
  'zfbManage.authentication': '实名认证使用条款',
  'zfbManage.realNameVerifyDesc1':
    '您在加速QQ/微信前需完成实名认证。',
  'zfbManage.realNameVerifyDesc2':
    '当前实名认证服务由“支付宝”提供，请您提前安装并认证相关应用。',
  'zfbManage.realNameVerifyDesc3':
    '您提交的身份信息仅用作实名认证使用，QuickFox承诺不将其应用于任何其他场景，更不会透露给其他第三方。',
  'zfbManage.qRealNameverify': 'QuickFox实名认证',
  'zfbManage.enterRealName': '请输入身份证真实姓名',
  'zfbManage.realName': '真实姓名',
  'zfbManage.enterRealID': '请输入本人的18位身份证号',
  'zfbManage.IDCode': '身份证号',
  'zfbManage.now': '立即认证',
  'zfbManage.go': '前往认证中',
  'zfbManage.upgrade': '当前客户端不支持该接口，请升级',
  'zfbManage.failed': '加载失败',
  'zfbManage.sendData': '解密发送的数据',
};

const blacklistBlocking = {
  'blacklist.youRisk': '您的帐号存在风险',
  'blacklist.cannot': '暂无法参与福利中心任务',
  'blacklist.sendEmail': '如有异议，请邮件联系info@quickfox.com.cn进行申诉',
  'vip.cantuse': '企业子账号无法进行此操作'
};

//
const zh_CN = {
  ...about,
  ...contact,
  ...help,
  ...helpDetail,
  ...http,
  ...memberServiceAgreement,
  ...monthlyVipAgreement,
  ...notice,
  ...Welfare,
  ...PageTitle,
  ...Common,
  ...Tips,
  ...ActivationRecord,
  ...TimeType,
  ...tutorial,
  ...pointEventEnd,
  ...taskCenterV3,
  ...pointEventV4,
  ...cancel,
  ...memberi,
  ...sportsQuiz,
  ...device,
  ...customizedMembership,
  ...bindEmail,
  ...taskSupplement,
  ...wxPayManage,
  ...zfbManage,
  ...blacklistBlocking,
  // ...News,
  // ...apply,
  // ...center,
  // ...Agent,
  // ...lines,
  // ...order,
  // ...reward,
  // ...invite,
  // ...purchase,
  // ...LoginPage,
  // ...withdrawal,
  // ...inviteDetail,
  // ...questionnaire,
  // ...activitydetails,
  // ...withdrawalRecord,
};

export default zh_CN;

// 一些暂时废弃的页面内容
// const Agent = {
//   'agent.title.meInvite': '我邀请的',
//   'agent.title.rechargeFriend': '充值好友',
//   'agent.title.incomeDetail': '收益明细',
//   'agent.title.accountAdd': '添加收款账户',
//   'agent.title.account': '收款账户',
//   'agent.title.explain': '代理说明',

//   'agent.entrys.withdrawal': '提现账户',
//   'agent.entrys.face2face': '面对面邀请',
//   'agent.entrys.incomeDetail': '收益明细',
//   'agent.entrys.poster': '宣传海报',

//   'agent.myInviteCode': '我的邀请码',
//   'agent.incomeBalance': '收益余额',
//   'agent.totalIncome': '总收益',
//   'agent.moneyUnit': '元',
//   'agent.inviteNum': '邀请好友数',
//   'agent.rechargeNum': '充值好友数',

//   'agent.goInvite': '邀请好友',
//   'agent.copyShareLink': '复制分享链接',

//   'agent.level.no': '----',
//   'agent.level.junior': '初级代理',
//   'agent.level.senior': '高级代理',
//   'agent.level.member': '合伙人',

//   'agent.f2f.myInviteQRCode': '我的邀请二维码',
//   'agent.f2f.desc': '让好友扫码二维码, 打开页面完成注册',

//   'agent.account.empty': '您还没有收款账户',
//   'agent.account.goAdd': '去添加',
//   'agent.account.del': '删除',

//   'agent.account.add.title.account': '请输入收款账号',
//   'agent.account.add.input.account': '请输入收款账号',
//   'agent.account.add.title.realname': '请输入真实姓名',
//   'agent.account.add.input.realname': '真实名字用户核验账户真实性',
//   'agent.account.add.add': '添加',
//   'agent.account.add.selectPlat': '请选择收款平台',
//   'agent.account.add.plat.alipay': '支付宝',
// };

// const News = {
//   'news.pagination.prev': '上一页',
//   'news.pagination.next': '下一页',
// };

// // 代理申请审核
// const apply = {
//   'apply.tab.title': '推广代理',
//   'apply.title': '推广招募计划',
//   'apply.tips.1': '您已经提交过申请',
//   'apply.tips.2': '正在审核中',
//   'apply.tips.3': '请您耐心等待',
//   'apply.btn': '审核中',
//   'apply.explain.1': '若有其他问题请联系我们的客服微信号：quickfox2020',
//   'apply.explain.2': '或官方邮箱：info@quickfox.com.cn',
// };

// // 提现
// const withdrawal = {
//   'withdrawal.tab.title': '提现',
//   'withdrawal.title': '您当前可提现的收益金额为：',
//   'withdrawal.prompt': '请输入提现金额',
//   'withdrawal.account': '您还未绑定收款账户',
//   'withdrawal.modify': '选择收款账号',
//   'withdrawal.add': '添加收款账号',
//   'withdrawal.btn.apply': '申请提现',
//   'withdrawal.explain.1': '·每个月15日后可提现上个月及之前收益',
//   'withdrawal.explain.2': '·可提现金额超100元才可提现',
//   'withdrawal.tips': '您有 {count} 笔提现正在处理中',
//   'withdrawal.toast.insufficient': '你的提现金额不足100元',
//   'withdrawal.toast.overstep': '您填写的提现金额超出可提现金额',
//   'withdrawal.actionsheet.title': '选择收款账户',
//   'withdrawal.pay.unit': '$',
//   'withdrawal.pay.unit_cn': '￥',
// };

// // 提现记录
// const withdrawalRecord = {
//   'withdrawalrecord.tab.title': '提现记录',
//   'withdrawalrecord.record.total': '共 {total} 条记录',
//   'withdrawalrecord.withdrawal': '提现',
//   'withdrawalrecord.withdrawal.total': '共提现',
//   'withdrawalrecord.status.ongoing': '审核中',
//   'withdrawalrecord.status.completed': '已到账',
//   'withdrawalrecord.status.reject': '已驳回',
//   'withdrawalrecord.pay.unit': '$',
//   'withdrawalrecord.pay.unit_cn': '￥',
// };

// // 邀请
// const invite = {
//   'invite.tab.title': '邀请送会员',
//   'invite.title.quickfoxExclusiveInvitation': 'QuickFox专属邀请',
//   'invite.title.friendsSendYou3DaysQuickfoxFreeMembership':
//     '好友送您3天QuickFox免费会员',
//   'invite.title.whenBindingFillInTheInvitationCodeBelowToGet':
//     '注册时填写下方的邀请码即可获得',
//   'invite.title.oneClickHomeToEnjoyTheDomesticNetwork': '回国加速 快人一步',
//   'invite.title.globalNode': '全球节点',
//   'invite.title.games&Video': '游戏&影音',
//   'invite.title.longTermStability': '长久稳定',
//   'invite.title.7x24Hours': '7X24小时',
//   'invite.title.specialLineBackToChina': '专线回国',
//   'invite.title.smoothOperator': '畅通无阻',
//   'invite.title.neverDropTheLine': '永不掉线',
//   'invite.title.carefreeService': '无忧服务',
//   'invite.title.numberOfPeopleYouInvited': '您邀请的人数',
//   'invite.title.daysYouGet': '您获得的天数',
//   'invite.title.inviteYourFriendsToUseQuickfox': '邀请您的朋友一起使用QuickFox',
//   'invite.title.yourExclusiveInvitationCode': '您的专属邀请码',
//   'invite.title.rule1': '1.当您的邀请码被新用户使用, 双方都会获得3天会员奖励',
//   'invite.title.rule2': '2.邀请码在新用户绑定界面填写',
//   'invite.title.rule3':
//     '3.奖励天数会累加, 使用您的邀请码人数越多, 您获得的奖励天数也会越多',
//   'invite.title.rule4': '4.同一设备仅限一个账号领取',
//   'invite.btn.copy': '复制邀请码',
//   'invite.btn.shareWithFriends': '分享给朋友',
//   'invite.btn.iOSDownload': 'IOS下载',
//   'invite.btn.androidDownload': 'Android下载',
// };

// // 邀请
// const inviteDetail = {
//   'inviteDetail.tab.title': '邀请送会员',
// };

// // 线路
// const lines = {
//   'lines.tab.title': '线路选择',
//   'lines.title.free': '空闲',
//   'lines.title.normal': '正常',
//   'lines.title.crowding': '拥挤',
//   'lines.title.fiery': '火爆',
//   'lines.title.maintain': '维护',
//   'lines.tabs.gameLine': '游戏模式',
//   'lines.tabs.soundAndShadowCircuit': '影音模式',
//   'lines.btn.quickConnect': '快速连接',
// };

// // 购买
// const purchase = {
//   'purchase.tab.title': '购买会员',
//   'purchase.title.discount': '折',
//   'purchase.title.originalPrice': '原价',
//   'purchase.title.asLowAs': '低至',
//   'purchase.title.tag.us': '$',
//   'purchase.title.tag.cn': '￥',

//   'purchase.title.silverMember': '白银会员',
//   'purchase.title.goldMember': '黄金会员',
//   'purchase.title.upTo50m/S': '高达50m/s',
//   'purchase.title.gameLine': '游戏专线',
//   'purchase.title.audioVisualSpecialLine': '影音线路',
//   'purchase.title.modeIntelligentSwitching': '模式智能切换',
//   'purchase.title.antiDelayTechnology': '抗延迟技术',
//   'purchase.title.privacyEncryption': '隐私加密',
//   'purchase.title.upTo100M/S': '高达100m/s',
//   'purchase.title.exclusiveCustomerService': '专属客服',
//   'purchase.title.moreExclusiveLines': '更多独享专线',
//   'purchase.title.pleaseSelectPaymentMethod': '请选择支付方式',
//   'purchase.title.privilegeDescription': '特权说明',
//   'purchase.title.alipay': '支付宝',
//   'purchase.title.weChat': '微信',
//   'purchase.btn.buyNow': '立即购买',
// };

// // 任务中心
// const center = {
//   'center.tab.title': '任务中心',
//   'center.list.register': '注册账号',
//   'center.list.bind': '绑定账号',
//   'center.list.questionnaire': '新人问卷调查',
//   'center.list.invite': '邀请好友',
//   'center.list.wx': '关注QuickFox微信公众号',
//   'center.list.offcial': '加入QuickFox官方群',
//   'center.list.register.desc':
//     '使用手机号或邮箱注册账号即可获得白银会员3天时长奖励',
//   'center.list.bind.desc': '完成所有绑定账号任务即可获得白银会员2天时长奖励',
//   'center.list.questionnaire.desc': '完成问卷调查后即可获得白银会员2天时长奖励',
//   'center.list.invite.desc': '邀请成功后获得白银会员3天时长奖励',
//   'center.list.wx.desc': '关注QuickFox微信公众号立即获得白银会员1天时长奖励',
//   'center.list.offcial.desc': '加入QuickFox官方群立即获得白银会员1天时长奖励',
//   'center.list.op.register': '去注册',
//   'center.list.op.bind': '去绑定',
//   'center.list.op.questionnaire': '去完成',
//   'center.list.op.invite': '去分享',
//   'center.list.op.wx': '去关注',
//   'center.list.op.offcial': '去加入',
//   'center.list.op.get': '去领取',
//   'center.list.op.complete': '已完成',
//   'center.register.tips': '请先注册账号',
//   'center.get.success': '领取成功！',
//   'center.get.fail': '领取失败！',
// };

// // 问卷调查
// const questionnaire = {
//   'questionnaire.tab.title': '问卷调查',
//   'questionnaire.title':
//     '为了为您更好的提供服务, 请按实际情况填写, 完成本次小调查将会奖励2天白银会员时长',
//   'questionnaire.submit': '提交',
//   'questionnaire.success': '提交成功',
//   'questionnaire.fail': '提交失败',
//   'questionnaire.loading': '正在提交...',
//   'questionnaire.picker.extra': '请选择',
//   'questionnaire.picker.title': '选择地区',
//   'questionnaire.slider.title': '请滑动滑块',
//   'questionnaire.textarea.placeholder': '期待您的宝贵建议',
//   'questionnaire.submit.tips': '请填写所有选项后提交',
// };

// // 活动详情
// const activitydetails = {
//   'activitydetails.tab.title': '活动详情',
//   'activitydetails.title':
//     'QuickFox支持国内所有主流游戏影视音乐APP, 帮助海外华人、留学生、海外出差和旅行人员一键解锁国内所有游戏和应用, 全球无限制畅享国内网络。',
//   'activitydetails.content':
//     '为庆祝QuickFox加速器正式上线, 现免费赠送1个月VIP会员。',
//   'activitydetails.tips': '扫码领取, 一键回国',
//   'activitydetails.copy.title': '客服微信: quickfox2020',
//   'activitydetails.op.copy': '复制号码',
//   'activitydetails.copy': '复制成功',
// };

// // 订单
// const order = {
//   'order.tradeNo': '订单号',
//   'order.tab.title': '我的订单',
//   'order.pay.unit': '$',
//   'order.pay.unit_cn': '￥',
//   'order.pay': '去支付',
//   'order.pay.success': '支付成功',
//   'order.expired': '已失效',
//   'order.nodata': '当前暂无数据~',
// };

// // 奖励记录
// const reward = {
//   'reward.tab.title': '奖励记录',
// };
