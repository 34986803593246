import axios from 'axios';
import Cookie from './Cookie';
import { JudgePf } from './JudgePf';
import RootStore from '~/stores';
import postMessage from './PostMessage';
import { HttpEncryption } from './Encryption';
import LocalData from './LocalData';
import NativeTools from './NativeTools';
import Tools from './Tools';
const apiUrl = Tools.getConstUrls().host;

// 创建axios实例
const Http = axios.create({
  timeout: 1000 * 60 * 3,
  baseURL: `${apiUrl}/`,
});
// 请求
Http.interceptors.request.use((config) => {
  const token = Cookie.getCookie('token');
  config.headers['Content-Type'] =
    config.headers['Content-Type'] || 'application/json';
  config.headers['lang'] = LocalData.getItem('lang') || 'zh_CN';
  config.headers['platformType'] = JudgePf().platformType;
  config.headers['version'] = NativeTools.getVersion();
  config.headers['channel'] = NativeTools.getMarketPlace();
  config.headers['h5'] = 1;

  // console.log('version:' + NativeTools.getVersion());

  if (token) {
    config.headers['token'] = token;
  }
  // 测试
  const API_ENV = process.env.API_ENV;
  if (API_ENV === 'local') {
    // config.headers['token'] = "1-64a18c0245376caa31f0e1cc85020a"
    // config.headers['token'] = "1-3f08660ad6b441c1822d762aa22dca";
    // config.headers['token'] = "1-95d392839bed8dacc399c5227ca3e5";
    config.headers['token'] = "1-b9203a8bce36bd8638cabec935a63a";
  }
  if (config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
    config.data = config.data;
    return config;
  }
  if (API_ENV !== 'prod') {
    console.log(
      '解密发送的数据',
      config.url,
      config.params,
      config.headers.token,
      config.data
    );
    // //对接环境切换（正式环境没有）
    // let apihost =
    //   LocalData.getItem('apiHost')?.trim() || Tools.getConstUrls().host;
    // config.baseURL = `${apihost}/`;
  }

  let rootStore = RootStore.getInstance();
  if (!rootStore.loading.disabledLoading) rootStore.loading.changeLoading(true);
  const data = HttpEncryption.encrypt(config.data);

  config.data = { data };
  return config;
});

// 响应
Http.interceptors.response.use(
  (response) => {
    let rootStore = RootStore.getInstance();
    if (!rootStore.loading.disabledLoading)
      rootStore.loading.changeLoading(false);
    // token 过期 清空cookie
    // if (response?.data?.code === -2) {
    //   localStorage.clear();
    //   Cookie.clearCookie();
    //   window.location.href = '/';
    // }
    //10079为金币不足 此时data包含补差价信息 需要解密
    //10104为签到不足
    //10108为抽奖库存不足
    //10109为抽奖金币不足
    //10028, "参数错误"
    //10042, "套餐不存在
    // 10115, "该会员卡已被好友领取"
    const needDecryptCodeArr = [
      200, 10079, 10104, 10108, 10109, 10028, 10042, 10115, 11099
    ];
    if (!needDecryptCodeArr.includes(response?.data?.code)) {
      return response.data;
    }
    if (response?.data?.code == 401) {
      // token 失效 调用cordova 返回客户端的登录页
      postMessage.send('tokenFail', null);
      return;
    }

    const noDecodedUrl = ['/wordpress/list', '/wordpress/info'];
    let data = response.data.data;
    if (noDecodedUrl.includes(response.config.url.split('?')[0]))
      return response.data;
    try {
      response.data.data = JSON.parse(HttpEncryption.decrypt(data));
    } catch (e) {
      response.data.data = HttpEncryption.decrypt(data);
    } finally {
      rootStore.loading.changeLoading(false);
    }
    const API_ENV = process.env.API_ENV;
    if (API_ENV !== 'prod') {
      console.log('解密返回的数据:', response.config.url, response.data);
    }
    return response.data;
  },
  (error) => {
    RootStore.getInstance().loading.changeLoading(false);
    return Promise.reject(error);
  }
);

export default Http;
