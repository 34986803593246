/* eslint-disable @typescript-eslint/no-require-imports */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, lazy } from 'react';
import { useHistory } from 'react-router-dom';
import { Redirect, Route, Switch } from 'react-router-dom';
import { NativeTools } from '~/utils';

const ZfbVerified = lazy(() => import('~/routes/Zfb'));
const Clause = lazy(() => import('~/routes/Clause'));
const Help = lazy(() => import('~/routes/Help'));
const HelpDetails = lazy(() => import('~/routes/Help/details'));
const Notice = lazy(() => import('~/routes/Notice'));
const Contact = lazy(() => import('~/routes/Contact'));
const About = lazy(() => import('~/routes/About'));

const Memberi = lazy(() => import('~/routes/Memberi'));
const XiechengAd = lazy(() => import('~/routes/XiechengAd'));
// const Lines = lazy(() => import('~/routes/Lines'));
const Tutorial = lazy(() => import('~/routes/Tutorial'));
//开通记录
const ActivationRecord = lazy(() => import('~/routes/ActivationRecord'));
// ==============裂变活动一期↓========================
// const Goods = lazy(() => import('~/routes/PointEvent/Goods'));
// const GoodsDetails = lazy(() => import('~/routes/PointEvent/GoodsDetails'));
// const RedeemRecord = lazy(() => import('~/routes/PointEvent/RedeemRecord'));
// const PointEvent = lazy(() => import('~/routes/PointEvent'));
const PointEventEnd = lazy(() => import('~/routes/PointEvent/PointEventEnd'));
// const RankList = lazy(() => import('~/routes/PointEvent/RankList'));
// ==============裂变活动一期 END====================

// ==============裂变活动二期↓========================
// const PointEventV2 = lazy(() => import('~/routes/PointEventV2'));
// const GoodsV2 = lazy(() => import('~/routes/PointEventV2/Goods'));
// const GoodsDetailsV2 = lazy(() => import('~/routes/PointEventV2/GoodsDetails'));
// const RedeemRecordV2 = lazy(() => import('~/routes/PointEventV2/RedeemRecord'));
const PointEventEndV2 = lazy(
  () => import('~/routes/PointEventV2/PointEventEnd')
);
// const InvitationDetailsV2 = lazy(
//   () => import('~/routes/PointEventV2/InvitationDetails')
// );
// const PointDetailsV2 = lazy(() => import('~/routes/PointEventV2/PointDetails'));

// const EventNoticeV2 = lazy(() => import('~/routes/PointEventV2/EventNotice'));
// ==============裂变活动二期 END====================

// ==============裂变活动三期↓========================
const TaskCenter = lazy(() => import('~/routes/PointEventV3/TaskCenter'));
const Follow = lazy(() => import('~/routes/PointEventV3/TaskDetails/Follow'));
// const Invite = lazy(() => import('~/routes/PointEventV3/TaskDetails/Invite'));
const Email = lazy(() => import('~/routes/PointEventV3/TaskDetails/Email'));
const Share = lazy(() => import('~/routes/PointEventV3/TaskDetails/Share'));
// const GoodsV3 = lazy(() => import('~/routes/PointEventV3/Goods'));
// const GoodsDetailsV3 = lazy(() => import('~/routes/PointEventV3/GoodsDetails'));
// const RedeemRecordV3 = lazy(() => import('~/routes/PointEventV3/RedeemRecord'));
// const InvitationDetailsV3 = lazy(() => import('~/routes/PointEventV3/InvitationDetails'));

const PointDetailsV3 = lazy(() => import('~/routes/PointEventV3/PointDetails'));

// const EventNoticeV3 = lazy(() => import('~/routes/PointEventV3/EventNotice'));
// const Welfare = lazy(() => import('~/routes/Welfare'));
// const SignIn = lazy(() => import('~/routes/PointEventV3/TaskDetails/SignIn'));
//抽奖
const Lottery = lazy(() => import('~/routes/PointEventV3/Lottery'));
// 赠送好友
const GiftFriends = lazy(() => import('~/routes/PointEventV3/GiftFriends'));
const CancelReminder = lazy(() => import('~/routes/Cancel/CancelReminder'));
const CancelAuth = lazy(() => import('~/routes/Cancel/CancelAuth'));
// ==============裂变活动三期 END====================

// ==============裂变活动四期↓========================
const WelfareCenter = lazy(() => import('~/routes/PointEventV4/WelfareCenter'));
const WelfareDetail = lazy(() => import('~/routes/PointEventV4/WelfareCenter/WelfareDetail'));
const MoreWelfare = lazy(() => import('~/routes/PointEventV4/WelfareCenter/MoreWelfare'));
const IntlStudent = lazy(() => import('~/routes/PointEventV4/WelfareCenter/IntlStudent'));
const ForeignRule = lazy(() => import('~/routes/PointEventV4/WelfareCenter/ForeignRule'));
const InviteV4 = lazy(() => import('~/routes/PointEventV4/Invite'));
const EventNoticeV4 = lazy(() => import('~/routes/PointEventV4/EventNotice'));
const InvitationDetailsV4 = lazy(() => import('~/routes/PointEventV4/InvitationDetails'));
const SignInV4 = lazy(() => import('~/routes/PointEventV4/WelfareCenter/SignIn'));
const RedeemRecordV4 = lazy(() => import('~/routes/PointEventV4/WelfareCenter/RedeemRecord'));
const CoinExchange = lazy(() => import('~/routes/PointEventV4/WelfareCenter/CoinExchange'));
// ==============裂变活动四期 END====================

const NotFound404 = lazy(() => import('~/routes/NotFound404'));
const VIP404 = lazy(() => import('~/routes/VIP404'));
const H5Redirect = lazy(() => import('~/routes/H5Redirect'));

// ===================其他 ↓=======================
const JsonDisplay = lazy(() => import('~/routes/JsonDisplay'));
const PurchaseTutorial = lazy(() => import('~/routes/Purchase/Tutorial'));
const SportsQuiz = lazy(() => import('~/routes/SportsQuiz'));
const WxPayManage = lazy(() => import('~/routes/WxPayManage'));
const EquipmentManage = lazy(() => import('~/routes/EquipmentManage'));
const ApplyRecord = lazy(() => import('~/routes/ApplyRecord'));
const CustomizedMembership = lazy(
  () => import('~/routes/CustomizedMembership')
);
const MailActivity = lazy(() => import('~/routes/MailActivity'));
const BlacklistBlocking = lazy(() => import('~/routes/BlacklistBlocking'));
const AppApplyRecord = lazy(() => import('~/routes/AppApplyRecord'));
// ==================其他 END======================

// ===================旧版页面↓=====================
// const Order = lazy(() => import('~/routes/Order'));
// const Invitation = lazy(() => import('~/routes/Invitation'));
// const InviteDetail = lazy(() => import('~/routes/Invitation/inviteDetail'));
// const Purchase = lazy(() => import('~/routes/Purchase'));
// const Center = lazy(() => import('~/routes/Center'));
// const Questionnaire = lazy(() => import('~/routes/Questionnaire'));
// const Reward = lazy(() => import('~/routes/RewardRecord'));
// ===================旧版页面 END==================

// ===================代理分佣↓=====================
// const Agent = lazy(() => import('~/routes/Agent'));
// const IncomeDetail = lazy(() => import('~/routes/Agent/IncomeDetail'));
// const ActivityDetails = lazy(() => import('~/routes/ActivityDetails'));
// const Apply = lazy(() => import('~/routes/Apply'));
// const Withdrawal = lazy(() => import('~/routes/Withdrawal'));
// const WithdrawalRecord = lazy(() => import('~/routes/WithdrawalRecord'));
// const BuddyList = lazy(() => import('~/routes/Agent/BuddyList'));
// const Account = lazy(() => import('~/routes/Agent/Account'));
// const Account_Add = lazy(() => import('~/routes/Agent/Add'));
// const Explain = lazy(() => import('~/routes/Agent/Explain'));
// ===================代理分佣 END==================

const RootRoute = () => {
  const history = useHistory();
  //响应pc
  const initPage = (event: any, url: string) => {
    // console.log('(响应initPage)url：', url);
    history.push(url);
  };
  const API_ENV = process.env.API_ENV;
  const isDev = API_ENV !== 'prod';

  useEffect(() => {
    let unsubscribe;
    (async () => {
      unsubscribe = await NativeTools.initPage(initPage);
    })();
    return () => {
      if (typeof unsubscribe === 'function') {  
        unsubscribe();  
      }
    };
  }, []);
  return (
    <Switch>
      {/* <Route path="/lines" component={Lines} /> */}
      <Route path="/equipmentManage" component={EquipmentManage} />
      <Route path="/customizedMembership" component={CustomizedMembership} />
      <Route path="/applyRecord" component={ApplyRecord} />
      <Route path="/wxPayManage" component={WxPayManage} />
      <Route path="/zfbVerified" component={ZfbVerified} />
      <Route path="/notice" component={Notice} />
      <Route path="/clause" component={Clause} />
      <Route path="/agreement" component={Clause} />
      <Route path="/help" component={Help} />
      <Route path="/helpDetails" component={HelpDetails} />
      <Route path="/contact" component={Contact} />
      <Route path="/about" component={About} />
      <Route path="/tutorial" component={Tutorial} />
      <Route path="/memberi" component={Memberi} />
      <Route path="/xiechengAd" component={XiechengAd} />
      {/* ==================邀请裂变一期 START==================== */}
      {/* <Route path="/goods" component={Goods} />
                <Route path="/goodsdetails" component={GoodsDetails} />
                <Route path="/redeemRecord" component={RedeemRecord} /> */}
      {/* <Route path="/pointEvent" component={PointEvent} /> */}
      {/* <Route path="/rankList" component={RankList} /> */}
      <Route path="/pointEvent" component={PointEventEnd} />
      {/* ==================邀请裂变一期 END====================== */}
      {/* ==================邀请裂变二期 START==================== */}
      <Route path="/pointEventV2" component={PointEventEndV2} />
      {/* <Route path="/goodsV2" component={GoodsV2} />
                <Route path="/goodsdetailsV2" component={GoodsDetailsV2} />
                <Route path="/redeemRecordV2" component={RedeemRecordV2} />
                <Route path="/endV2" component={PointEventEndV2} />
                <Route
                  path="/invitationDetailsV2"
                  component={InvitationDetailsV2}
                />
                <Route path="/pointDetailsV2" component={PointDetailsV2} />
                <Route path="/eventNoticeV2" component={EventNoticeV2} /> */}
      {/* ==================邀请裂变二期 END====================== */}
      {/* ==================邀请裂变三期 START==================== */}
      {/* <Route path="/taskCenterV3" component={TaskCenter} /> */}
      <Route path="/taskDetailWxV3" component={Follow} />
      <Route path="/taskDetailEmailV3" component={Email} />
      <Route path="/taskDetailShareV3" component={Share} />
      {/* <Route path="/taskDetailInviteV3" component={Invite} /> */}
      {/* <Route path="/goodsV3" component={GoodsV3} /> */}
      {/* <Route path="/goodsdetailsV3" component={GoodsDetailsV3} /> */}
      {/* <Route path="/pointDetailsV3" component={PointDetailsV3} /> */}
      {/* <Route path="/redeemRecordV3" component={RedeemRecordV3} /> */}
      {/* <Route path="/invitationDetailsV3" component={InvitationDetailsV3} /> */}
      {/* <Route path="/eventNoticeV3" component={EventNoticeV3} /> */}
      <Route path="/giftFriends" component={GiftFriends} />
      <Route path="/vip404" component={VIP404} />
      {/* ==================邀请裂变三期 END====================== */}
      {/* ==================邀请裂变四期 START==================== */}
      <Route path="/welfare" component={WelfareCenter} />
      <Route path="/welfareDetail" component={WelfareDetail} />
      <Route path="/moreWelfare" component={MoreWelfare} />
      <Route path="/intlStudent" component={IntlStudent} />
      <Route path="/inviteV4" component={InviteV4} />
      <Route path="/eventNoticeV4" component={EventNoticeV4} />
      <Route path="/invitationDetailsV4" component={InvitationDetailsV4} />
      <Route path="/signInV4" component={SignInV4} />
      <Route path="/foreignRule" component={ForeignRule} />
      <Route path="/redeemRecordV4" component={RedeemRecordV4} />
      <Route path="/coinExchange" component={CoinExchange} />
      {/* ==================邀请裂变四期 END==================== */}
      {/* APP2.0 新增页面↓ */}
      {/* 开通记录 */}
      <Route path="/activationRecord" component={ActivationRecord} />
      {/* 抽奖 */}
      <Route path="/lottery" component={Lottery} />
      {/* 会员服务协议 */}
      <Route path="/memberServiceAgreement" component={Clause} />
      {/* 连续包月协议 */}
      <Route path="/monthlyVipAgreement" component={Clause} />
      {/* 账号注销协议 */}
      <Route path="/cancelAccountAgreement" component={Clause} />
      {/* 注销提醒 */}
      <Route path="/cancelReminder" component={CancelReminder} />
      {/* 身份验证 */}
      <Route path="/cancelAuth" component={CancelAuth} />

      <Route path="/agreements" component={Clause} />

      {/* 工具页-ios展示某个json */}
      {isDev && <Route path="/displayJson" component={JsonDisplay} />}
      {/* 谷歌支付引导 */}
      <Route path="/purchaseTutorial" component={PurchaseTutorial} />
      <Route path="/sportsQuiz" component={SportsQuiz} />
      <Route path="/mailActivity" component={MailActivity} />
      <Route path="/blacklistBlocking" component={BlacklistBlocking} />
      <Route path="/appApplyRecord" component={AppApplyRecord} />
      {/* 订单 */}
      {/* <Route path="/order" component={Order} /> */}
      {/* (旧)个人中心 可通向调查问卷 */}
      {/* <Route path="/center" component={Center} /> */}
      {/* 购买 */}
      {/* <Route path="/purchase" component={Purchase} /> */}
      {/* <Route
        path="/purchasecn"
        component={() => <Purchase channel="iosCN" />}
      /> */}
      {/* 旧邀请页面(显示人数和已获得的天数) */}
      {/* <Route path="/invitation" component={Invitation} /> */}
      {/* 邀请页面(带下载按钮 已迁移到官网) */}
      {/* <Route path="/invitedetail" component={InviteDetail} /> */}
      {/* 调查问卷(奖励两天白银时长) */}
      {/* <Route path="/questionnaire" component={Questionnaire} /> */}
      {/* 活动页面(庆祝上线免费赠送1个月VIP) */}
      {/* <Route path="/activitydetails" component={ActivityDetails} /> */}
      {/* 代理 */}
      {/* <Route path="/agent" component={Agent} /> */}
      {/* <Route path="/incomeDetail" component={IncomeDetail} /> */}
      {/* <Route path="/buddy" component={BuddyList} /> */}
      {/* <Route path="/account" component={Account} /> */}
      {/* <Route path="/accountadd" component={Account_Add} /> */}
      {/* <Route path="/explain" component={Explain} /> */}
      {/* <Route path="/apply" component={Apply} /> */}
      {/* <Route path="/withdrawal" component={Withdrawal} /> */}
      {/* <Route path="/withdrawalrecord" component={WithdrawalRecord} /> */}
      {/* 兑换记录 */}
      {/* <Route path="/reward" component={Reward} /> */}
      {/* 马甲包(QuickFox资讯)使用的 资讯页面 */}
      {/* <Route path="/news" component={News} />
                  <Route path="/newsDetails" component={NewsDetails} /> */}
      <Route path="/h5Redirect" component={H5Redirect} />
      <Route path="/404" component={NotFound404} />
      <Redirect to="/404" />
    </Switch>
  );
};

export default RootRoute;
